.image-square {
    position: relative;
    border: 1px solid var(--shadowColor);
    color: var(--primaryColor);
    min-width: 100px;
}

.image-square img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

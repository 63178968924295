.modal {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    min-width: 320px;
    min-height: 200px;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .16);
    padding: calc(var(--wcGutterBasic)*4);
    border-radius: 2px;
    position: relative;
    max-height: 100vh;
    overflow: scroll;
}

.modal__close {
    display: flex;
    margin: 0;
    justify-content: flex-end;
    position: absolute;
    right: calc(var(--wcGutterBasic)*2);
    top: calc(var(--wcGutterBasic)*2);
}

.modal__title {
    margin: 0;
    font-size: var(--fs-m);
    margin-bottom: calc(var(--wcGutterBasic)*2);
}

.header {
  background: var(--whiteColor);
  color: var(--blackColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--wcGutterBasic);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.header__left {
  display: flex;
  width: 80%;
}

.header__logo {
  margin-right: calc(var(--wcGutterBasic) * 4);
  padding: 1px 0;
  padding-left: var(--wcGutterBasic);
  display: flex;
  font-size: calc(var(--wcGutterBasic) * 3);
  font-weight: bold;
  text-decoration: none;
  color: var(--primaryColor);
  margin: 0;
}
.header__logo .icon--large {
  max-height: 18px;
  max-width: 72px;
}

.header__logo img:not(.icon--large) {
  width: 24px;
}

.header__icons {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

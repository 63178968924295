@import './font/fontello.css';
@import './antd-override.css';
/* Variables */

:root {
    --primaryColor: #1B4964;
    --secondaryColor: #1B4964;
    --greenColor: #43b02a;
    --orangeColor: #f87e00;
    --blackColor: #302D2A;
    --mono1: #F8F8F8;
    --mono2: #E3E4E7;
    --mono3: #d1d1d1;
    --mono4: #989897;
    --mono5: #778072;
    --mono6: #53555C;
    --lightBg: var(--mono1);
    --gray: var(--mono2);
    --placeholder: var(--mono-4);
    --whiteColor: #fff;
    --greyColor: var(--mono5);
    --lighGreyColor: var(--mono-5);
    --dark: var(--mono6);
    --offWhiteColor: #FDFDFD;
    --shadowColor: #f7f7f7;
    --container-width: 1140px;
    --wcGutterBasic: 8px;
    --warningColor: var(--orangeColor);
    --successColor: var(--greenColor);
    --dangerColor: #D62828;
    --fs-m: 24px;
    --fs-n: 18px;
    --fs-xs: 12px;
    --b-rd-main: 24px;
    --b-rd-small: 8px;

    --shadow-1: 0px 0px 9px 1px rgb(0 0 0 / 12%);
    --shadow-2:  0px 4px 4px rgba(241, 241, 241, 0.67);
    --shadow-3: 0px 0px 6px #ECEBEB;
}


/* Font */

:root {
    --primaryFont: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

@media (min-width: 992px) {
     :root {
        --container-width: 970px;
    }
}

@media (min-width: 1200px) {
     :root {
        --container-width: 1170px;
    }
}


/* common styles */

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--primaryFont);
    background: var(--offWhiteColor);
    padding-top: 76px;
}

h1 {
    font-size: 24px;
}

.error {
    color: var(--secondaryColor);
}

.container {
    width: 100%;
    max-width: var(--container-width);
    margin-left: auto;
    margin-right: auto;
}

.main-content {
    min-height: 90vh;
}

.full-width {
    width: 100vw;
    text-align: center;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
}

@media screen and (min-width: 768px) {
    .full-width-with-padding {
        margin-left: calc(-50vw + 50%);
        margin-right: calc(-50vw + 50%);
        padding-left: calc(50vw - 50%);
        padding-right: calc(50vw - 50%);
    }
}

.muted,
.muted * {
    color: var(--mono4);
    font-size: 75%;
    text-decoration: none;
}

a.muted:hover {
    text-decoration: underline;
}

.muted--small,
.muted--small * {
    color: var(--mono4);
    font-size: 50%;
    opacity: 0.7;
    text-decoration: none;
}

.text-center {
    text-align: center;
    width: 100%;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    margin-left: 0;
    font-size: inherit;
}

@media screen and (max-width: 767px) {
    .hide-mobile {
        display: none;
    }
}

span.price--striked {
    color: var(--greyColor);
    font-size: 75%;
    text-decoration: line-through;
    font-weight: normal;
    margin-right: 4px;
}

.t-h1. .t-h2, .t-h3, .t-h4, .t-h4__bold {
    color: var(--blackColor);
}

.t-h5 {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    color: var(--greyColor);
}

.t-h4 {
    font-size: 12px;
    font-weight: 400;
}

.t-h4__bold {
    font-size: 12px;
    font-weight: 700;
}

.t-h3 {
    font-size: 16px;
    font-weight: 700;
}

.t-h2 {
    font-size: 18px;
    font-weight: 700;
}


.t-h1 {
    font-size: 24px;
    font-weight: 700;
}

a:hover  {
    color: var(--primaryColor);
}

.cart-page {
  padding: 0 15px;
}
.cart__buttons {
  display: grid;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 768px) {
  .cart__buttons {
    grid-template-columns: 1fr 300px;
  }
}
.cart__items-info {
  color: var(--greyColor);
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}
.cart__title {
  border-bottom: 1px solid rgba(217, 219, 224, 0.5);
  padding-bottom: 15px;
  margin: 10px 0 5px 0;
  position: relative;
}
.cart__title:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 33px;
  height: 2px;
  background: var(--primaryColor);
  left: 0;
}
.cart__title h1 {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .cart-page {
    padding: 0;
  }
}

.iconLink {
    /* margin-left: calc(var(--wcGutterBasic) * 5); */
    min-width: 50px;
    text-decoration: none;
    color: var(--colorBlack);
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 15px;
}
.iconLink__active {
    color: var(--primaryColor);
}

.icon {
    max-width: 35px;
    height: auto;
    object-fit: contain;
}

.icon--large {
    width: 100px;
    max-height: 40px;
    max-width: 100px;
    object-fit: contain;
}

.content {
    margin-left: var(--wcGutterBasic);
    display: none;
}

.content--hidden {
    display: none !important;
}

.content--mobile {
    display: block;
}

@media (min-width: 768px) {
    .content {
        display: block;
    }
    .iconLink {
        min-width: 120px;
    }
}

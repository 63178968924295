.header__categories {
    display: none;
}

.header__categories li {
    margin-right: calc(var(--wcGutterBasic) * 2);
    font-weight: bold;
    list-style: none;
    flex-shrink: 0;
}

.header__categories li a {
    text-decoration: none;
    color: var(--blackColor);
    font-weight: normal;
}

@media (min-width: 768px) {
    .header__categories {
        display: flex;
        max-width: 70%;
        align-items: center;
        margin: 0;
        font-size: 12px;
    }
}
.loading-indicator {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-indicator:before {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
}
.loader__small {
  position: absolute;
  background: #ffffffa6;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

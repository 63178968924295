.container {
  padding: 8px 16px;
  display: flex;
}
.searchForm {
  width: 100%;
  margin: 0;
}
.container input {
  width: 100%;
  max-width: 450px;
  flex-grow: 1;
}

.link {
    display: block;
    width: 100%;
    background: var(--primaryColor);
    color: var(--whiteColor);
    border-radius: 3px;
    transition: all 0.3s;
    padding: 8px;
    font-size: 14px;
    margin: 15px 0;
    text-decoration: none;
    font-style: bold;
    border: none;
    outline: none;
    opacity: 0.95;
}

.link:hover {
    cursor: pointer;
    opacity: 1;
}

.link--inline {
    display: inline-block;
    width: auto;
}

.link--normal {
    background: none;
    color: var(--primaryColor);
}

.link--danger {
    background: var(--secondaryColor);
}

.link--success {
    background: var(--greenColor);
}

@media screen and (min-width: 768px) {
    .link {
        display: inline-block;
        width: auto;
    }
}
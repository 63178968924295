.wrapper {
    margin: 15px 0;
    font-size: 14px;
    /* display: flex; */
}

.wrapper label {
    margin-right: 5px;
    min-width: 240px;
    display: inline-block;
    color: var(--blackColor);
    margin-bottom: var(--wcGutterBasic);
}

.wrapper input,
.wrapper input[type='text'] {
    border-radius: 4px;
    border: 1px solid #c6ccd5;
    outline: none;
    padding: 6px 8px;
    margin-bottom: 8px;
    color: #132437;
    font-size: 14px;
    font-weight: 300;
}

.wrapper input:focus {
    border-color: var(--primaryColor);
}

.wrapper span {
    display: flex;
}

.wrapper button {
    flex-basis: 34px;
    margin-bottom: 8px;
    margin-top: 0;
}

@media screen (min-width: 768px) {
    .wrapper label {
        display: block;
    }
}

.titleBar {
    border-bottom: 1px solid rgba(217, 219, 224, 0.5);
    padding-bottom: 15px;
    margin: 10px 0 5px 0;
    position: relative;
    font-size: var(--fs-n);
    margin-bottom: calc(var(--wcGutterBasic) * 3);
}

.titleBar:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 33px;
    height: 2px;
    background: var(--primaryColor);
    left: 0;
}

@media screen and (min-width: 768px) {
    .titleBar {
        margin: 10px 0 5px 0;
    }
}

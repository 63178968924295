.component {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--lightBg);
  box-shadow:var(--shadow-1);
  justify-content: space-between;
  z-index: 1;
}
.iconLink {
  padding: 8px;
  font-size: 24px;
}

@media screen and (min-width: 768px) {
  .component {
    display: none;
  }
}

.wc-error {
  position: fixed;
  right: 50px;
  bottom: 50px;
  padding: 20px 30px;
  padding-right: 50px;
  max-width: 300px;
  background: #ffdadd;
  color: #856b67;
  border: 1px solid #f7c9c9;
  box-shadow: 0px 6px 8px 0px #e0bfbf52;
  border-radius: 2px;
  outline: 0;
  z-index: 9999;
}

.wc-error span {
  color: #bfa19f;
  position: absolute;
  right: 16px;
  top: 11px;
  cursor: pointer;
}

.banner-carousel-slide {
  padding-top: 56.25%;
  position: relative;
}

/* .banner-carousel__image {
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);
} */

.banner-carousel-slide .banner-carousel-slide__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-fit: contain; */
}

@media screen and (min-width: 768px) {
  .column {
    width: 100%;
  }
  .banner-carousel-slide {
    padding-top: 25%;
  }
}

.horizontalBanner {
  max-width: 100%;
}

.horizontalBanner__images {
  display: block;
}

.horizontalBanner .banner-carousel-slide {
  padding-top: 50%;
  border: 1px solid var(--mono2);
  border-radius: 3px;
  box-shadow: 3px 3px 6px 1px #afafaf1f;
}

@media screen and (min-width: 768px) {
  .horizontalBanner__images {
    grid-template-columns: repeat(14, 500px);
  }
  .horizontalBanner {
    padding: calc(var(--wcGutterBasic) * 3) 0;
  }
}

.products-grid__container {
  margin: 20px 0 30px 0;
}

.products-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
}

.product-card {
  flex-basis: 25%;
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  border-radius: var(--b-rd-small);
  border: 1px solid var(--mono2);
  box-shadow: var(--shadow-2);
  position: relative;
}

.product-card__image-wrapper {
  padding-top: 56.25%;
  position: relative;
}

.product-card__currency {
  font-size: 80%;
  opacity: 0.8;
}
.product-card__content-wrapper {
  padding: 8px;
}

.product-card__image-wrapper img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
}

.product-card__meta {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  flex-direction: column;
}

.product-card__title {
  text-transform: capitalize;
  display: flex;
  margin-top: 12px;
  margin-bottom: 4px;
  flex-grow: 1;
}

.product-card__description {
  margin-bottom: 8px;
}

.product-card__price {
  margin-top: 0;
  margin-right: 15px;
}

.product-card__label {
  margin-top: 0;
  font-size: 10px;
  font-weight: 400;
  color: var(--whiteColor);
  background: var(--whiteColor);
  border-radius: 4px;
  position: absolute;
  left: 8px;
  top: 66px;
  width: 16px;
  height: 16px;
  display: flex;
  border-radius: 1px;
  align-items: center;
  justify-content: center;
}

.product-card__label_circle {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
.product-card__label--green .product-card__label_circle {
  background: var(--greenColor);
}

.product-card__label--red .product-card__label_circle {
  background: var(--dangerColor);
}

.product-card__label--green {
  border: 1px solid var(--greenColor);
}

.product-card__label--red {
  border: 1px solid var(--dangerColor);
}

.product-card__cta {
  margin-bottom: 5px;
  width: 100%;
  padding: 10px;
  background: none;
  border-radius: 10px;
  border: 1px solid rgb(236, 236, 236);
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  transition: background 0.3s, color 0.15s;
  text-transform: uppercase;
}

.product-card__cart_op {
  display: flex;
  justify-content: center;
  background: var(--whiteColor);
  color: var(--dark);
  align-items: center;
  height: 40px;
  border: none;
  box-shadow: var(--shadow-3);
  border-radius: 32px;
  flex-grow: 1;
  width: 100%;
  margin: 4px;
}
.product-card__cart_op_empty {
  display: flex;
  background: var(--greenColor);
  color: var(--whiteColor);
}
.product-card__cart_op_reduce {
  background: var(--dangerColor);
  color: var(--whiteColor);
}
.product-card__cart_op p {
  margin-bottom: 0;
}

.product-card__cart_op button {
  border: none;
  width: 100%;
}

.product-card__cta--error {
  border: none;
  color: var(--dangerColor);
  pointer-events: none;
  font-weight: bold;
}

.product-card__cta--small {
  width: auto;
  min-width: 50px;
}
.product-card__count-wrapper {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

.product-card__count {
  flex-grow: 1;
  text-align: center;
}

/* responsiveness */

@media screen and (min-width: 768px) {
  .products-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .product-card {
    min-width: 240px;
  }
  .products-grid__container {
    margin: 20px 0 50px;
    border-bottom: 1px solid #eeeeee73;
    padding-bottom: 6px;
  }
}

.product--fullscreen {
  position: fixed;
  top: 68px;
  left: 0;
  right: 0;
  padding: 36px;
  z-index: 1;
  background: white;
  height: calc(100vh - 68px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eee;
}

.product--fullscreen .product-card {
  max-width: 500px;
}

.button {
    width: 100%;
    display: block;
    background: var(--whiteColor);
    color: var(--mono4);
    transition: all 0.3s;
    padding: var(--wcGutterBasic) calc(var(--wcGutterBasic) * 2);
    /* May needed. remove after testing */
    /* font-size: 14px; */
    font-weight: 300;
    border-radius: 3px;
    border: 1px solid var(--mono4);
    margin: var(--wcGutterBasic) 0;
}

.button:disabled {
    opacity: 0.95;
    pointer-events: none;
}
.button:hover {
    cursor: pointer;
    filter: contrast(1.25);
}

.button--primary:hover {
    background: var(--secondaryColor);
}

.button--primary {
    background: var(--primaryColor);
    color: var(--whiteColor);
    border: 1px solid var(--mono1);
}

.button--warning {
    background-color: var(--warningColor);
    color: var(--whiteColor);
    border: 1px solid var(--mono1);
}

.button--success {
    background-color: var(--successColor);
    color: var(--whiteColor);
    border: 1px solid var(--mono1);
}

.button--danger {
    background-color: var(--dangerColor);
    color: var(--whiteColor);
    border: 1px solid var(--mono1);
}

@media screen and (min-width: 768px) {
    .button {
        display: inline-block;
        width: auto;
    }
}

/* TODO: Cleanup. Copied from ProductsGrid styles */

.products-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}

.products-list__row {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 100px 100px 100px;
    color: var(--greyColor);
    font-size: 14px;
    grid-gap: 5px;
}

.products-list__row--main {
    font-weight: bold;
    color: var(--blackColor);
    grid-template-columns: 1fr 200px;
}

@media screen and (min-width: 768px) {
    .products-list__row--main {
        grid-template-columns: 1fr 200px 100px 100px;
    }
  }

.products-list__row--main span {
    font-weight: normal;
    color: var(--greyColor);
    font-size: 80%;
}

.products-list__row__total {
    text-align: right;
    margin-top: 10px;
    padding: 0 15px;
    text-align: right;
}

.product-list-item {
    padding: 15px 0;
    min-width: 240px;
    flex-basis: 100%;
    width: 100%;
    font-size: 14px;
    display: grid;
    min-width: 100%;
    justify-content: stretch;
    grid-template-columns: 1fr 1fr;
}

.product-list-item__image-wrapper {
    padding-top: 150px;
    position: relative;
    flex-basis: 150px;
    grid-column: 1 / 3;
}

.product-list-item__image-wrapper img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.product-list-item__title {
    margin-top: 10px;
    margin-bottom: 7px;
    text-transform: uppercase;
    flex-grow: 1;
    padding: 0 15px;
    font-size: 16px;
}

.product-list-item__title h5 {
    margin: 0;
    font-weight: normal;
    text-transform: none;
}

.product-list-item__price,
.product-list-item__count {
    color: var(--grey);
    margin-top: 10px;
    padding: 0 15px;
    text-align: right;
}

.product-list-item__count-wrapper {
    display: flex;
    align-items: center;
    font-size: var(--fs-xs);
}

.product-list-item__cta {
    padding: 10px;
    background: none;
    border-radius: 0;
    border: 1px solid #eee;
    font-weight: 300;
    font-size: 12px;
    cursor: pointer;
    transition: background 0.1s, color 0.15s;
}

.product-list-item__cta:hover {
    background: var(--greyColor);
    color: var(--whiteColor);
}


/* responsiveness */

@media screen and (min-width: 768px) {
    .products-grid {
        justify-content: flex-start;
    }
    .product-list-item {
        display: grid;
        grid-template-columns: 150px 1fr 100px 150px 50px;
        grid-gap: 5px;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    .product-list-item__image-wrapper {
        grid-column: 1;
    }
}

.cart-icon {
  position: relative;
  margin-left: calc(var(--wcGutterBasic) * 5);
  text-decoration: none;
  color: var(--colorBlack);
  display: flex;
  align-items: center;
}
.icon {
  max-width: 35px;
  height: auto;
  object-fit: contain;
}
.number {
  margin-left: var(--wcGutterBasic);
}

.cartIconCount {
  background: var(--primaryColor);
  color:var(--whiteColor);
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48%;
  left: -13px;
  top: -6px;
  position: relative;
}


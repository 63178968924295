.footer {
    background: var(--blackColor);
    padding: 60px;
    color: var(--whiteColor);
    display: flex;
    flex-wrap: wrap;
}

.footer__main,
.footer__social,
.footer__links,
.footer__copyright {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: var(--wcGutterBasic);
}

.footer__social ul,
.footer__links {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 5px;
}

.footer__social ul li,
.footer__links li {
    padding: 3px 5px;
}

.footer__social,
.footer__copyright {
    font-size: 12px;
    opacity: 0.8;
}